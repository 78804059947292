import React from 'react';
import Page from '../components/Page';

class Body extends React.Component {
  /*constructor(props) {
    super(props);
  }*/

  render () {
    return <Page step={4} handleStep={this.props.handleStep}>Body</Page>
  }
}

export default Body;