import React from 'react';

class PageImages extends React.Component {
  constructor(props) {
    super(props);
    this.imgs = this.props.imgs;
    this.imgDefault = this.props.imgDefault;

  }

  componentDidMount() {
    this.imgs.forEach((picture) => {
      //console.log('pic', picture.img)
      const img = new Image();
      img.src = picture.img;
    });
  }

  changeImg = (e, img) => {
    e.preventDefault();
    //console.log(img)
    this.setState({
      image: img
    });
  }

  state = {
    image: this.props.imgDefault,
  }


  render() {
    const ImageSelected = () => {
      //console.log(this.state)
      return (
        <div>
          <img
            src={this.state.image}
            alt="Produzida" />
        </div>
      );
    }

    return (
      <>
        <ImageSelected />
        <div className="listImg">
          {this.imgs.map((q, idx) =>
            <div
              className={'blocksColor ' + (this.state.image === q.img ? 'active' : '') }
              style={{background: q.color}}
              key={idx}
              src={q.img }
              onClick={(event) => this.changeImg(event, q.img)}
              alt="block">
              {
                q.minImg ? <img src={q.minImg} alt="color" /> : ''
                //console.log('xxxx',q)
              }

            </div>
          )}
        </div>
      </>
    )
  }
}

export default PageImages;