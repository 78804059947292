import React from 'react';
import Page from '../components/Page';
import PageImages from '../components/PageImages';

import brown from '../eyes/brown.png';
import blue from '../eyes/blue.png';
import green from '../eyes/green.png';

class Eyes extends React.Component {
  constructor(props) {
    super(props);
    this.imgs = [
       { color: '#6f3a2c' /*'#884400'*/, img: brown }
      ,{ color: '#92adb6' /*'#0066cc'*/, img: blue }
      ,{ color: '#535e41' /*#339900'*/, img: green }
    ];
  }

  render () {
    return <Page step={this.props.step} handleStep={this.props.handleStep}>
        <h1>Eyes</h1>
        <PageImages title="Eyes" imgs={this.imgs} imgDefault={brown} />
      </Page>
  }
}

export default Eyes;