import React from 'react';
import Page from '../components/Page';
import PageImages from '../components/PageImages';


import deep_block from '../skins/deep_5450.png.webp';
import deep from '../skins/deep.jpg';
import fair_block from '../skins/fair_4450.png.webp';
import fair from '../skins/fair.jpg';
import fair_light_block from '../skins/fair_light_4550.png.webp';
import fair_light from '../skins/fair_light.jpg';
import light_block from '../skins/light_4650.png.webp';
import light from '../skins/light.jpg';
import light_medium_block from '../skins/light_medium_4750.png.webp';
import light_medium from '../skins/light_medium.jpg';
import medium_block from '../skins/medium_4850.png.webp';
import medium from '../skins/medium.jpg';
import medium_tan_block from '../skins/medium_tan_5050.png.webp';
import medium_tan from '../skins/medium_tan.jpg';
import neutral_medium_block from '../skins/neutral_medium_4950.png.webp';
import neutral_medium from '../skins/neutral_medium.jpg';
import neutral_tan_block from '../skins/neutral_tan_5150.png.webp';
import neutral_tan from '../skins/neutral_tan.jpg';
import rich_block from '../skins/rich_5250.png.webp';
import rich from '../skins/rich.jpg';
import rich_honey_block from '../skins/rich_honey_5350.png.webp';
import rich_honey from '../skins/rich_honey.jpg';
import tan_block from '../skins/tan_7350.png.webp';
import tan from '../skins/tan.jpg';

class Skin extends React.Component {
  constructor(props) {
    super(props);
    this.imgs = [
      { img: fair, minImg: fair_block }
      , { img: fair_light, minImg: fair_light_block }
      , { img: light, minImg: light_block }
      , { img: light_medium, minImg: light_medium_block }
      , { img: medium, minImg: medium_block }
      , { img: neutral_medium, minImg: neutral_medium_block }
      , { img: neutral_tan, minImg: neutral_tan_block }
      , { img: medium_tan, minImg: medium_tan_block }
      , { img: tan, minImg: tan_block }
      , { img: rich, minImg: rich_block }
      , { img: rich_honey, minImg: rich_honey_block }
      , { img: deep, minImg: deep_block }
    ]
  }

  render() {
    return (
      <Page step={this.props.step} handleStep={this.props.handleStep}>
        <h1>Skin</h1>
        <PageImages title="Skin" imgs={this.imgs} imgDefault={fair} />
      </Page>
    )
  }
}

export default Skin;