import React from 'react';
import Page from '../components/Page';

class Face extends React.Component {
  /*constructor(props) {
    super(props);
  }*/

  render () {
    return <Page step={1} handleStep={this.props.handleStep}>Face</Page>
  }
}

export default Face;