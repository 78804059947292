import React, { useState } from 'react';
//load pages
import Body from './pages/Body';
import Eyes from './pages/Eyes';
import Face from './pages/Face';
import Hair from './pages/Hair';
import Skin from './pages/Skin';
import Questions from './pages/Questions';

//import './style.css';

function App() {
  const [page, setPage] = useState('skin');
  const pages = ['skin','face','eyes','hair','body','questions'];
  const [pageNum, setPageNum] = useState(pages.indexOf(page));




  const components = {
    skin: Skin,
    face: Face,
    eyes: Eyes,
    hair: Hair,
    body: Body,
    questions: Questions,
  };

  function DynamicPage(props) {

    const LoadPage = components[props.page];
    return <LoadPage step={props.step} handleStep={handleStep} />;
  }

  const handleStep = () => {

    let pageNum = pages.indexOf(page);

    pageNum++;

    switch(pageNum)
    {
      case 1: setPage('face'); break;
      case 2: setPage('eyes'); break;
      case 3: setPage('hair'); break;
      case 4: setPage('body'); break;
      case 5: setPage('questions'); break;
      default: 
        setPage('skin');
        pageNum = 0; 
        break;
    }

    setPageNum(pageNum)
  }

  return <DynamicPage step={pageNum} page={page} />;
}

export default App;
