import React from 'react';
import Page from '../components/Page';
import PageImages from '../components/PageImages';

import hair1 from '../hairs/hair1.png';
import hair2 from '../hairs/hair2.png';
import hair3 from '../hairs/hair3.png';
import hair4 from '../hairs/hair4.png';
import hair5 from '../hairs/hair5.png';
import hair6 from '../hairs/hair6.png';
import hair7 from '../hairs/hair7.png';
import hair8 from '../hairs/hair8.png';
import hair9 from '../hairs/hair9.png';
import hair10 from '../hairs/hair10.png';
import hair11 from '../hairs/hair11.png';
import hair12 from '../hairs/hair12.png';


class Hair extends React.Component {
  constructor(props) {
    super(props);
    this.imgs = [
      { color: '#f8f6ef', img: hair1 }
      , { color: '#faf6e3', img: hair2 }
      , { color: '#f7edd6', img: hair3 }
      , { color: '#f7edd6', img: hair4 }
      , { color: '#d3ba7b', img: hair5 }
      , { color: '#caae82', img: hair6 }
      , { color: '#a58261', img: hair7 }
      , { color: '#7e6759', img: hair8 }
      , { color: '#5e4e46', img: hair9 }
      , { color: '#322425', img: hair10 }
      , { color: '#362f32', img: hair11 }
      , { color: '#211e22', img: hair12 }
    ]
  }


  render() {

    return (
      <Page step={this.props.step} handleStep={this.props.handleStep}>
        <h1>Hair</h1>
        <PageImages title="Hair" imgs={this.imgs} imgDefault={hair1} />
      </Page>
    )
  }
}

export default Hair;