import React from 'react';
import Page from '../components/Page';

class Questions extends React.Component {
  /*constructor(props) {
    super(props);
  }*/

  render () {
    return <Page step={5} handleStep={this.props.handleStep}>Questions</Page>
  }
}

export default Questions;